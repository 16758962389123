import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "cancelling-requests"
    }}>{`Cancelling Requests`}</h1>
    <p>{`You can use the `}<inlineCode parentName="p">{`cancel`}</inlineCode>{` function provided by the hook to cancel an in-flight request.`}</p>
    <p>{`Common use cases:`}</p>
    <ul>
      <li parentName="ul">{`Allow the user to cancel a request`}</li>
      <li parentName="ul">{`Cancel a pending request when a new request is triggered`}</li>
    </ul>
    <p><em parentName="p">{`Note: Requests are automatically cancelled when a component hosting a hook is unmounted.`}</em></p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useLazyAxios } from 'use-axios-client';

export default () => {
  const [saveData, { loading, cancel }] = useLazyAxios({
    url: 'https://example/api',
  });

  return (
    <>
      <Button disabled={loading} onClick={() => saveData({ name: 'JP' })}>
        Save
      </Button>

      <Button disabled={!loading} onClick={() => cancel()}>
        Cancel
      </Button>
    </>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      